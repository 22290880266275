import React, { Component, PureComponent } from 'react';
import {NavLink} from 'react-router-dom'

import './index.scss'

import OurStoryPage from './OurStoryPage'
import Catering from './Catering'
import Taconight from './Taconight'
export {OurStoryPage as ourstory}
export {Catering as catering}
export {Taconight as taconight}

// export const customOrderAppPaths = ["/menu", "/order"]

export class SiteNav extends PureComponent {
  render() {
    const pages = [
      {path: "/", label: "Order"},
      // {path: "/catering", label: "Catering"},
      // {path: "/taco-night", label: "Taco Night"},

    ]
    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, external}) => (
          <li key={path}>
            {external ?
              <a       className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        ))}
      </ul>
    </nav>
  )}
}



export class Header extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper">
        <div className="landing-page">

          <div className="text">
            <div className="contact">


            </div>
            <p>Order Togo and Delivery Here ⤵</p>
            <small>Sushi & Donburi</small>
            {/* <div className="hero-logo">
              <img src="https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_scale,w_800/v1547835899/danstuff/taco-poncitlan-logo_nitdkh.png" alt=""/>
            </div> */}
            {/* <img src="https://res.cloudinary.com/dgps1radv/image/upload/v1546023510/danstuff/burgerama-hero.png" alt=""/> */}
            {/* <p>Gourmet burgers made to order!</p> */}
            {/* <p>Vist Us -></p> */}
            {/* <p>Vegan options 🌱 </p> */}

          </div>


        </div>
      </div>
    );
  }
}


export function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
  const isLunch = (
    (hours >= 11) && (minutes >= 30) && (hours < 15)
  ) || (
    (hours >= 12) && (hours < 15)
  )
  const showLunchMenu = isWeekday && isLunch

  const isLunchSubmenu = tag.indexOf('lunch') !== -1
  const isDinnerSubmenu = tag.indexOf('dinner') !== -1

  if (isLunchSubmenu && showLunchMenu) { return true }
  if (isDinnerSubmenu && !showLunchMenu) { return true }

  return true
}
