import {get, round} from 'lodash'
export default function applyDiscount(menu) {
  try {

    if (["cravestudiocity", 'cravesunset'].indexOf(menu.shopId) !== -1) {


      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                const productName = get(product, 'name.en', '').toLowerCase()
                const containsChicken = productName.indexOf('chicken') !== -1
                const containsVegan = productName.indexOf('vegan') !== -1
                if (containsChicken && !containsVegan) {
                  const originalDescription = get(product, 'description.en', '')
                  return Object.assign({}, product, {
                    // originalPrice: product.price,
                    price: round(Number(product.price) + 3, 2),
                    // description: {en: "⚠️$3 temporary surcharge is included in chicken dishes due to raising cost. Thanks for your understanding⚠️" + originalDescription}
                  })
                } else {
                  return product
                }
              })
            })
          }
          return subMenu
        })
      })
    }
    // if (["incanorthhills", 'incamaya'].indexOf(menu.shopId) !== -1) {
    //   return Object.assign({}, menu, {
    //     subMenus: menu.subMenus.map((subMenu) => {
    //       if (true) {
    //         return Object.assign({}, subMenu, {
    //           // apply discount
    //           products: subMenu.products.map((product) => {
    //             if (product.name && (product.name.en.toLowerCase().indexOf('saltado') != -1)) {
    //               return Object.assign({}, product, {
    //                 originalPrice: product.price,
    //                 price: Math.floor(product.price * 0.5 * 100) / 100
    //               })
    //             }
    //             return product
    //           })
    //         })
    //       }
    //       return subMenu
    //     })
    //   })
    // }
    if (["themelt", "muiishi"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (subMenu.tag === '50off') {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.5 * 100) / 100
                })
              })
            })
          }
          return subMenu
        })
      })
    }

    if (["mikaza"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (subMenu.tag && subMenu.tag.indexOf('5dollar') !== -1) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: 5
                })
              })
            })
          }
          return subMenu
        })
      })
    }

    if (["pablitostacosnoho"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (subMenu.tag && subMenu.tag.indexOf('20off') !== -1) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.8 * 100) / 100,
                })
              })
            })
          }
          if (subMenu.tag && subMenu.tag.indexOf('-noho-breakfast') !== -1) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.75 * 100) / 100,
                })
              })
            })
          }
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  // originalPrice: product.price,
                  // price: Math.floor(product.price * 0.85 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Fried Oyster') === -1)  && (modifier.name.indexOf('NO GREEN SAUCE') === -1) && (modifier.name.indexOf('Green Birria') === -1))
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    // if (["kokorollburbank"].indexOf(menu.shopId) !== -1) {
    //   return Object.assign({}, menu, {
    //     subMenus: menu.subMenus.map((subMenu) => {
    //       if (true) {
    //         return Object.assign({}, subMenu, {
    //           // apply discount
    //           products: subMenu.products.map((product) => {
    //             let modgroups = product.modgroups || []
    //             return Object.assign({}, product, {
    //               // originalPrice: product.price,
    //               // price: Math.floor(product.price * 0.8 * 100) / 100,
    //               modgroups: modgroups.map((modgroup) => {
    //                 if (!modgroup.modifiers) { return modgroup }
    //                 return Object.assign({}, modgroup, {
    //                   modifiers: modgroup.modifiers.filter((modifier) => {
    //                     return (modifier.name && (modifier.name.indexOf('Beef Bulgogi') === -1) && (modifier.name.indexOf('Shrimp') === -1) && (modifier.name.indexOf('Boba') === -1))
    //                   })
    //                 })
    //               })
    //             })
    //           })
    //         })
    //       }
    //       return subMenu
    //     })
    //   })
    // }

    if (["domenicos"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  // originalPrice: product.price,
                  // price: Math.floor(product.price * 0.9 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Sierra Mist (2L)') === -1)
                        //  && (modifier.name.indexOf('Mortadella') === -1)
                        //  && (modifier.name.indexOf('Prosciutto') === -1)
                        //  && (modifier.name.indexOf('28') === -1)
                        //  && (modifier.name.indexOf('Goat Cheese') === -1)
                        )
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    

    if (["-pablitostacosnorth"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.85 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Sweet Potato Fries') === -1)
                        )
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    // if (["beachwood"].indexOf(menu.shopId) !== -1) {
    //   if ((new Date()).getHours() >= 16) {
    //     return menu
    //   }
    //   return Object.assign({}, menu, {
    //     subMenus: menu.subMenus.map((subMenu) => {
    //       if (true) {
    //         return Object.assign({}, subMenu, {
    //           // apply discount
    //           products: subMenu.products.map((product) => {
    //             let modgroups = product.modgroups || []
    //             return Object.assign({}, product, {
    //               // originalPrice: product.price,
    //               // price: Math.floor(product.price * 0.8 * 100) / 100,
    //               modgroups: modgroups.map((modgroup) => {
    //                 if (!modgroup.modifiers) { return modgroup }
    //                 return Object.assign({}, modgroup, {
    //                   modifiers: modgroup.modifiers.filter((modifier) => {
    //                     return ((modifier.name.indexOf('16 Inch') === -1))
    //                   })
    //                 })
    //               })
    //             })
    //           })
    //         })
    //       }
    //       return subMenu
    //     })
    //   })
    // }
    // if (["kst"].indexOf(menu.shopId) !== -1) {
    //   return Object.assign({}, menu, {
    //     subMenus: menu.subMenus.map((subMenu) => {
    //       if (true) {
    //         return Object.assign({}, subMenu, {
    //           // apply discount
    //           products: subMenu.products.map((product) => {
    //             let modgroups = product.modgroups || []
    //             return Object.assign({}, product, {
    //              // originalPrice: product.price,
    //               // price: Math.floor(product.price * 0.8 * 100) / 100,
    //               modgroups: modgroups.map((modgroup) => {
    //                 if (!modgroup.modifiers) { return modgroup }
    //                 return Object.assign({}, modgroup, {
    //                   modifiers: modgroup.modifiers.filter((modifier) => {
    //                     return (modifier.name && (modifier.name.indexOf('Kimchi') === -1) && (modifier.name.indexOf('Kimichi') === -1))
    //                   })
    //                 })
    //               })
    //             })
    //           })
    //         })
    //       }
    //       return subMenu
    //     })
    //   })
    // }

    if (["sushidon-"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  // originalPrice: product.price,
                  // price: Math.floor(product.price * 0.8 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Brown Rice') === -1) && (modifier.name.indexOf('Crunchy Tempura Flakes') === -1))
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    

    if (["kokorolltorrance"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  // originalPrice: product.price,
                  // price: Math.floor(product.price * 0.8 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Cheddar') === -1) && (modifier.name.indexOf('Dragon') === -1))
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }

    if (menu.shopId.indexOf('kluckin') !== -1) {
      console.log('kluckin!')
      menu.subMenus.unshift({"draft":false,"name":{"en":"Special"},"orderIndex":[],"type":"subMenu","shopId":"kluckin","id":"QA6vknKrHm5eDdXW8DpC","products":[{"id":"SFHLzgdXdnEhbv16UC3m","name":{"en":"Kluckin' Ten"},"price":"10","description":{"en":"1 Tender, Kluckin' Mac, Fries."},"modgroups":[{"modifiers":[{"id":"m12","price":null,"name":"No Heat­"},{"name":"Kluckin Mild","id":"m22","price":null},{"id":"m32","name":"Kluckin Hot","price":null},{"name":"Kluckin Fire!","price":null,"id":"m42"}],"id":"we2","name":"How Kluckin Spicy do you want it ?","required":true,"type":"single"},{"type":"buffet","modifiers":[{"name":"Kluckin Sauce","id":"sauce","price":0.5},{"id":"pickles","name":"Extra Pickles","price":0.5}],"id":"extra","name":"Add Ons"}],"photo":{"h":1184,"e":"png","w":1076,"s":"the-kluckin-chicken","id":"kluckin-ten","type":"g"},"sub":"Special","tags":","}]})
      // return Object.assign({}, menu, {
      //   subMenus: 
      // })
    }

   

    if (["pablitostacosburbank"].indexOf(menu.shopId) !== -1) {
      
      
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          
          if (subMenu.tag === 'family-packs') {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.9 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.map((modifier) => {
                        return Object.assign({}, modifier, {
                          price: Math.floor(modifier.price * 0.9 * 100) / 100,
                        })
                      })
                    })
                  })
                })
              })
            })
          }
          
          if (subMenu.tag === '25off') {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.75 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.map((modifier) => {
                        return Object.assign({}, modifier, {
                          price: Math.floor(modifier.price * 0.75 * 100) / 100,
                        })
                      })
                    })
                  })
                })
              })
            })
          }



          if (subMenu.tag && subMenu.tag.indexOf('50off') !== -1) {
            // console.log('detected 50off')
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.5 * 100) / 100,
                })
              })
            })
          }
          if (subMenu.tag && subMenu.tag.indexOf('20off') !== -1) {
            // console.log('detected 50off')
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.8 * 100) / 100,
                })
              })
            })
          }

          if (subMenu.tag && subMenu.tag.indexOf('add-impossible-burger') !== -1) {
            // console.log('detected 50off')
            return subMenu
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.75 * 100) / 100,
                })
              })
            })
          }

          

          return Object.assign({}, subMenu, {
            // apply discount
            products: subMenu.products.map((product) => {
              let modgroups = product.modgroups || []
              return Object.assign({}, product, {
                // originalPrice: product.price,
                // price: Math.floor(product.price * 0.8 * 100) / 100,
                modgroups: modgroups.map((modgroup) => {
                  if (!modgroup.modifiers) { return modgroup }
                  return Object.assign({}, modgroup, {
                    modifiers: modgroup.modifiers.filter((modifier) => {
                      return (modifier.name && (modifier.name.indexOf('Fried Oyster') === -1)
                      // && (modifier.name.indexOf('POLLO') === -1)
                      // && (modifier.name.indexOf('Chicken Mole') === -1)
                      )
                    })
                  })
                })
              })
            })
          })

          

     
          return subMenu
        })
      })
    }


    if (["pablitosworld"].indexOf(menu.shopId) !== -1) {
      
      
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          
          if (subMenu.tag === 'family-packs') {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.9 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.map((modifier) => {
                        return Object.assign({}, modifier, {
                          price: Math.floor(modifier.price * 0.9 * 100) / 100,
                        })
                      })
                    })
                  })
                })
              })
            })
          }
 
 
          if (subMenu.tag && subMenu.tag.indexOf('25off') !== -1) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.75 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.map((modifier) => {
                        return Object.assign({}, modifier, {
                          price: Math.floor(modifier.price * 0.75 * 100) / 100,
                        })
                      })
                    })
                  })
                })
              })
            })
          }



          if (subMenu.tag && subMenu.tag.indexOf('50off') !== -1) {
            // console.log('detected 50off')
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.5 * 100) / 100,
                })
              })
            })
          }
          if (subMenu.tag && subMenu.tag.indexOf('20off') !== -1) {
            // console.log('detected 50off')
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.8 * 100) / 100,
                })
              })
            })
          }

          if (subMenu.tag && subMenu.tag.indexOf('add-impossible-burger') !== -1) {
            // console.log('detected 50off')
            return subMenu
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.75 * 100) / 100,
                })
              })
            })
          }

          

          return Object.assign({}, subMenu, {
            // apply discount
            products: subMenu.products.map((product) => {
              let modgroups = product.modgroups || []
              return Object.assign({}, product, {
                // originalPrice: product.price,
                // price: Math.floor(product.price * 0.8 * 100) / 100,
                modgroups: modgroups.map((modgroup) => {
                  if (!modgroup.modifiers) { return modgroup }
                  return Object.assign({}, modgroup, {
                    modifiers: modgroup.modifiers.filter((modifier) => {
                      return (modifier.name && (modifier.name.indexOf('Fried Oyster') === -1)
                      // && (modifier.name.indexOf('POLLO') === -1)
                      // && (modifier.name.indexOf('Chicken Mole') === -1)
                      )
                    })
                  })
                })
              })
            })
          })

          

     
          return subMenu
        })
      })
    }

    
    if (["frontierwok-", 'sushidon-', 'muiishi-'].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.9 * 100) / 100,
                  
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    if (["kokorolltorrance"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                if (!product.modgroups) {return product}
                return Object.assign({}, product, {
                  modgroups: product.modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Dragon Fruit') === -1))
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }

    if (["coralcafe"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          let tag = subMenu.tag || ''
          if (tag.indexOf('no-increase') === -1) {
            return Object.assign({}, subMenu, {
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  price: Number(product.price) + 1,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Planks') === -1))
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }

    if (["lancers"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          let tag = subMenu.tag || ''
          if (tag.indexOf('no-increase') === -1) {
            return Object.assign({}, subMenu, {
              products: subMenu.products.map((product) => {
                if (!Number(product.price)) {
                  return product
                }
                return Object.assign({}, product, {
                  price: Math.floor((Number(product.price) +1) * 100) / 100
                })
              })
            })
          }
          return subMenu
        })
      })
    }

    if (["gourmet88"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          let tag = subMenu.tag || ''
          if (tag.indexOf('no-increase') === -1) {
            return Object.assign({}, subMenu, {
              products: subMenu.products.map((product) => {
                if (!Number(product.price)) {
                  return product
                }
                return Object.assign({}, product, {
                  price: ((Number(product.price) + 1))
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    
  if (["unclereds"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                if (!product.modgroups) {return product}
                return Object.assign({}, product, {
                  modgroups: product.modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Spinach') === -1))
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    // if (["pablitostacosburbank"].indexOf(menu.shopId) !== -1) {
    //   return Object.assign({}, menu, {
    //     subMenus: menu.subMenus.map((subMenu) => {
    //       if (true) {
    //         return Object.assign({}, subMenu, {
    //           // apply discount
    //           products: subMenu.products.filter((product) => {
    //             let {name, description} = product
    //             name = (name && name.en) || ''
    //             description = (description && description.en) || ''
    //             const noSausageName = (name.toLowerCase().indexOf('chicharron') === -1)
    //             const noSausageDesc = description.toLowerCase().indexOf('chicharron') === -1
    //             return noSausageName && noSausageDesc
    //           })
    //         })
    //       }
    //       return subMenu
    //     })
    //   })
    // }


    if (["newdeal", 'wildcarvery'].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (subMenu.tag && (subMenu.tag.indexOf('discount-50-off') !== -1)) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.5 * 100) / 100
                })
              })
            })
          }
          if (subMenu.tag && (subMenu.tag.indexOf('discount-30-off') !== -1)) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.7 * 100) / 100
                })
              })
            })
          }
          if (subMenu.tag && (subMenu.tag.indexOf('wine') !== -1)) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  // originalPrice: product.price,
                  // price: Math.floor(product.price * 0.8 * 100) / 100
                })
              })
            })
          }
          // if (subMenu.tag && (subMenu.tag.indexOf('spirits') !== -1)) {
          //   return Object.assign({}, subMenu, {
          //     // apply discount
          //     products: subMenu.products.map((product) => {
          //       return Object.assign({}, product, {
          //         originalPrice: product.price,
          //         price: Math.floor(product.price * 0.75 * 100) / 100
          //       })
          //     })
          //   })
          // }
          return subMenu
        })
      })
    }


    return menu
  } catch (e) {
    console.log(e)
    return menu
  }

}
